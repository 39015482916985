import { Component, Inject } from '@angular/core';
import { DirtyComponent } from '../../../helpers/CanDeactivate/dirty-component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CustomValidator } from '../../../helpers/custom.validator';
import { WithdrawalRequestsService } from '../company-withdrawal-requests/services/withdrawal-requests.service';
import { Patterns } from '../../../helpers/patterns';

export interface DialogData {
  balance: number
}

@Component({
  selector: 'app-request-withdrawal-points',
  templateUrl: './request-withdrawal-points.component.html',
  styles: ``
})
export class RequestWithdrawalPointsComponent extends DirtyComponent {

  loading = false;
  dataLoading = false;
  override formGroup!: FormGroup;
  isSubmitted = false;
  balance!: any
  balancePattern = Patterns.price;
  constructor(
    override dialogRef: MatDialogRef<RequestWithdrawalPointsComponent>,
    translate: TranslateService,
    private formBuilder: FormBuilder,
    private service: WithdrawalRequestsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    super(translate);
  }

  ngOnInit(): void {
    this.balance = this.data?.balance;
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      num_points: [
        null, [
          CustomValidator.noWhiteSpace,
          Validators.required,
          Validators.max(this.balance),
          Validators.min(0.001),
        ]
      ],
    })
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      let data = {
        num_points: this.formGroup.controls['num_points'].value,
      }

      this.service.sendWithdrawalRequest(data).subscribe((res) => {
        this.loading = false;
        this.dialogRef.close(true);
        Swal.fire({
          title: this.translate.instant('companyAdminProfile.Withdrawal request sent successfully'),
          text: '',
          imageUrl: './assets/images/icons/success.svg',
          confirmButtonText: this.translate.instant('swalMsg.Ok')
        });
        // this.service.refreshList.next(true);
      }, error => {
        this.loading = false;
      });
    }

  }
}
